<template>
  <LayoutVendor>
    <template v-slot:title>MATERIALS AND SUPPLIES</template>
    <template v-slot:topbar>
      <!-- Vendor Add Product Btn -->
      <div class="d-flex justify-content-end">
        <!-- Desktop Button -->
        <AppBtn
          class="text-uppercase mr-3 d-none d-md-inline-block"
          @click="openAddTypes"
        >
          Add new product
        </AppBtn>
        <!-- Mobile Add Button -->
        <AppIcon
          name="add_filled"
          class="ml-2 text-primary d-md-none"
          @click="openAddTypes"
        ></AppIcon>

        <!-- Select a type of product to add (not yet done) -->
        <AppModal v-model="addTypeModal" width="800px">
          <div class="p-4">
            <div class="d-flex justify-content-end">
              <AppIcon
                hoverable
                name="close"
                @click="addTypeModal = false"
              ></AppIcon>
            </div>
            <h5 class="text-md-center mt-n4 mt-md-3">
              SELECT TYPE OF PRODUCT TO BE UPLOADED
            </h5>

            <!-- Actial Typs -->
            <div class="mt-4 row">
              <div
                v-for="(type, index) in addTypes"
                :key="`add-type-${index}`"
                class="col-12 col-md-4"
                @click="chooseOption(index)"
              >
                <div
                  class="
                    add-product-item
                    pt-md-5
                    pb-md-2
                    p-1
                    mb-3
                    d-flex
                    flex-md-column
                    align-items-center
                    justify-content-md-center
                    text-uppercase
                  "
                  :class="{ active: index === addIndex }"
                >
                  <img :src="type.img" alt="" />
                  <h5 class="ml-3 ml-md-0 mt-4">{{ type.name }}</h5>
                </div>
              </div>
            </div>

            <!-- confirm btn -->
            <div
              class="
                mt-md-5
                d-flex
                flex-column flex-md-row
                justify-content-center
              "
            >
              <AppBtn
                class="rounded-sm"
                :disabled="addIndex === false"
                @click="confirm"
              >
                CONFIRM
              </AppBtn>
            </div>
          </div>
        </AppModal>
      </div>
    </template>
    <div class="product-list m-auto w-100 overflow-hidden">
      <!-- Header Options -->
      <div class="filter p-2">
        <AppBtn
          text
          color="black"
          prependIcon="settings_adjust"
          class="mb-2 p-0 filter-btn d-md-none"
          @click="showMobileFilter = !showMobileFilter"
        >
          Filters
        </AppBtn>
        <div
          class="row filter-container d-md-flex"
          :class="{ 'd-none': showMobileFilter, 'd-block': !showMobileFilter }"
        >
          <!-- Category -->
          <div class="col-12 col-md-3">
            <AppInputSelect
              v-model="filters.category"
              label="category"
              :items="categories"
            ></AppInputSelect>
          </div>
          <!-- Inventory -->
          <div class="col-12 col-md-3">
            <AppInputSelect
              v-model="filters.inventory"
              label="Inventory"
              :items="[
                { text: 'All', value: 'all' },
                { text: 'In Stock', value: 'stock' },
                { text: 'Out of Stock', value: 'no stock' },
              ]"
            ></AppInputSelect>
          </div>
          <!-- Draft and Live -->
          <div class="col-12 col-md-3">
            <AppInputSelect
              v-model="filters.status"
              label="Draft and Live"
              :items="[
                { text: 'All', value: 'all' },
                { text: 'Live', value: 'live' },
                { text: 'Draft', value: 'draft' },
              ]"
            ></AppInputSelect>
          </div>
        </div>
      </div>
      <!-- Table -->
      <AppLoading
        v-if="productsWithCategory.length === 0 && loading"
      ></AppLoading>
      <div v-else>
        <div class="d-none d-md-block">
          <AppTable
            @sort="sortBy"
            :headers="header"
            :items="productsWithCategory"
          >
            <template v-slot:item="{ shownItem }">
              <td class="d-md-flex align-items-center">
                <AppMediaViewer
                  width="40px"
                  aspectRatio="1/1"
                  :src="shownItem | thumbnail"
                ></AppMediaViewer>
                <span class="ml-2">{{ shownItem.name }}</span>
              </td>
              <td>{{ shownItem.category }}</td>
              <td>
                {{
                  shownItem.user.company !== null &&
                  typeof shownItem.user.company === 'object'
                    ? shownItem.user.company.name
                    : ''
                }}
              </td>
              <td>{{ shownItem | price }}</td>
              <td>{{ getProductStocks(shownItem) }}</td>
              <td>
                <li v-if="hasStock(shownItem)" class="text-success">
                  In Stock
                </li>
                <li v-else class="text-danger">Out of stock</li>
              </td>
              <td>
                <span v-if="shownItem.status === 'draft'" class="text-danger">
                  Draft
                </span>
                <span
                  v-else-if="shownItem.is_active === 1"
                  class="text-success"
                >
                  Active
                </span>
                <span v-else class="text-danger">Inactive</span>
              </td>
              <td>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-items-center
                    align-content-center
                    justify-content-center
                  "
                >
                  <AppTooltip tooltipText="Delete product">
                    <AppIcon
                      hoverable
                      name="trash_can"
                      class="text-danger mr-2"
                      @click="confirmDelete = shownItem.id"
                    ></AppIcon>
                  </AppTooltip>
                  <router-link
                    tag="div"
                    :to="{ name: 'ProductEdit', params: { id: shownItem.id } }"
                  >
                    <AppTooltip tooltipText="Edit product">
                      <AppIcon
                        hoverable
                        name="edit"
                        class="text-info"
                      ></AppIcon>
                    </AppTooltip>
                  </router-link>
                  <router-link
                    tag="div"
                    class="ml-2"
                    :to="{ name: 'ProductView', params: { id: shownItem.id } }"
                  >
                    <AppTooltip tooltipText="View product">
                      <AppIcon
                        hoverable
                        name="visible"
                        class="text-info"
                      ></AppIcon>
                    </AppTooltip>
                  </router-link>
                  <AppBtn
                    outline
                    :name="shownItem.is_active === 1 ? 'locked' : 'unlocked'"
                    :disabled="shownItem.status === 'draft'"
                    class="w-50 ml-3 text-secondary"
                    @click="
                      toggleProductActive(
                        shownItem.id,
                        shownItem.is_active === 0
                      )
                    "
                  >
                    {{ shownItem.is_active === 1 ? 'Deactivate' : 'Activate' }}
                  </AppBtn>
                </div>
              </td>
            </template>
          </AppTable>
        </div>
        <div class="d-md-none p-2">
          <AppTable
            centerPagination
            paginationHideDetail
            :headers="headerMobile"
            :items="products"
            @itemClick="
              (item) => {
                $router.push({
                  name: 'ProductEdit',
                  params: { id: item.id },
                });
              }
            "
          >
            <template v-slot:item="{ shownItem }">
              <td class="d-flex align-items-center">
                <AppMediaViewer
                  width="40px"
                  aspectRatio="1/1"
                  :src="shownItem | thumbnail"
                ></AppMediaViewer>
                <span class="ml-2">{{ shownItem.name }}</span>
              </td>
              <td>{{ vendorName(shownItem) }}</td>
            </template>
          </AppTable>
        </div>
      </div>

      <ActionModal
        v-model="confirmDelete"
        persistConfirm
        state="delete"
        header="Are you sure you want to delete this product?"
        description="Click confirm to proceed"
        @confirm="deleteProduct(confirmDelete)"
      ></ActionModal>
      <SuccessModal v-model="successDelete" :withDescription="false">
        <template v-slot:title> PRODUCT DELETED! </template>
        <template v-slot:action-btn-text> Close </template>
      </SuccessModal>
    </div>
  </LayoutVendor>
</template>

<script>
import AppModal from '@/shared/elements/AppModal.vue';
import AppTable from '@/shared/elements/AppTable.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppInputSelect from '@/shared/elements/AppInputSelect.vue';
import Products from '@/shared/api/Products';
import Company from '@/shared/api/Company';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import _strings from '@/shared/mixins/_strings';
import AppLoading from '@/shared/elements/AppLoading.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import AppTooltip from '../../../../shared/elements/AppTooltip.vue';
import SuccessModal from '../../../../components/Partial/Modals/SuccessModal.vue';
import Vue from 'vue';

export default {
  name: 'VendorProductList',

  components: {
    AppModal,
    AppTable,
    AppMediaViewer,
    AppIcon,
    AppInputSelect,
    ActionModal,
    AppLoading,
    AppBtn,
    LayoutVendor,
    AppTooltip,
    SuccessModal,
  },

  props: {
    sort: { type: Array, default: () => [] },
  },

  mixins: [_appApiHelper, _strings],

  data() {
    return {
      addTypeModal: false,
      addIndex: false,
      addTypes: [
        {
          name: 'Materials & Supplies',
          img: require('@/assets/products/svg/Material.svg'),
          route: { name: 'ProductAdd' },
        },
        {
          name: 'Equipment',
          img: require('@/assets/products/svg/Equipment.svg'),
          route: { name: 'ProductAdd' },
        },
        {
          name: 'Services',
          img: require('@/assets/products/svg/Services.svg'),
          route: { name: 'ProductAdd' },
        },
      ],
      header: [
        {
          text: 'Product Name',
          value: 'name',
          desc: this.isDesc('name'),
        },
        { text: 'Category', value: 'category', sortable: false },
        { text: 'Vendor', value: 'vendor', sortable: false },
        { text: 'Price', value: 'price', sortable: false },
        { text: 'Quantity', value: 'quantity', sortable: false },
        { text: 'Inventory', value: 'inventory', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: '', sortable: false },
      ],
      headerMobile: [
        { text: 'Product Name', value: 'name', desc: this.isDesc('name') },
        { text: 'Vendor', value: 'vendor', sortable: false },
      ],
      categories: [],
      vendors: {
        current_page: 0,
        last_page: 0,
        data: [],
      },
      products: [],
      sorts: this.sort,
      filters: {
        vendor: null,
        category: null,
        inventory: 'all',
        status: 'all',
      },
      confirmDelete: false,
      successDelete: false,
      showMobileFilter: true,
    };
  },

  computed: {
    productsWithCategory() {
      const categories = this.categories;
      return this.products.map((product) => {
        const category = categories.find((category) => {
          return category.value === product.category_id;
        });
        return {
          ...product,
          category: category?.text || 'Uncategorized',
        };
      });
    },
  },

  watch: {
    filters: {
      handler() {
        this.fetchProducts();
      },
      deep: true,
    },
  },

  filters: {
    thumbnail(value) {
      if (value && value?.files.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
    price(value) {
      const price_start = Vue.options.filters.toMoney(value.price_start);
      const price_end = Vue.options.filters.toMoney(value.price_end);
      const price_fixed = Vue.options.filters.toMoney(value.price_fixed);

      if (price_start && price_end) {
        return `$ ${price_start} - $ ${price_end}`;
      } else {
        return `$ ${price_fixed}`;
      }
    },
    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    sortBy({ item, desc }) {
      const existingIndex = this.sorts.findIndex(
        (value) => value.item === item
      );
      if (existingIndex < 0) {
        this.sorts.push({ item, orderBy: desc ? 'DESC' : 'ASC' });
      } else {
        this.sorts[existingIndex].orderBy = desc ? 'DESC' : 'ASC';
      }
      this.fetchProducts();
      this.$emit('sort', this.sorts);
    },
    isDesc(value) {
      return this.sort.find((val) => val.item === value)?.orderBy === 'DESC';
    },
    openAddTypes() {
      this.addTypeModal = true;
    },
    chooseOption(index) {
      this.addIndex = index;
    },
    confirm() {
      const gotoSrc = this.addTypes[this.addIndex].route;
      this.addTypeModal = false;
      this.$router.push(gotoSrc);
    },
    async fetchCategories() {
      const response = await Products.getCategories();
      this.categories = [
        {
          value: 'all',
          text: 'All',
        },
        ...response.data.map((category) => ({
          value: category.id,
          text: category.category,
        })),
      ];

      if (this.$route.query.category_id) {
        this.filters.category = Number(this.$route.query.category_id);
      }
    },
    async fetchCompanies() {
      const page = this.vendors?.current_page + 1 || 1;
      const response = await Company.getCompanies({
        params: { vendor_only: 1, page },
      });
      this.vendors = {
        ...response.data,
        data: [...this.vendors.data, ...response.data.data],
      };

      if (this.$route.query.vendor_id) {
        this.filters.vendor = Number(this.$route.query.vendor_id);
      }
    },
    async fetchProducts() {
      const sorts = this.sorts.map((value) => {
        return {
          sort: value.item,
          sort_direction: value.orderBy.toLowerCase(),
        };
      });

      const filters = { ...this.filters };
      const query = { ...this.$route.query };

      const params = {
        per_page: 10,
      };

      if (this.sorts.length <= 0) {
        params.sort_by_newest = 1;
      } else {
        params.sort = sorts[0].sort;
        params.sort_direction = sorts[0].sort_direction;
      }

      if (filters.vendor !== 'all') {
        params.vendor_id = filters.vendor;
        query.vendor_id = filters.vendor;
      } else {
        delete query.vendor_id;
      }

      if (filters.inventory === 'no stock') {
        params.out_of_stock_only = 1;
        query.inventory = filters.inventory;
      } else if (filters.inventory === 'stock') {
        params.in_stock_only = 1;
        query.inventory = filters.inventory;
      } else {
        delete query.inventory;
      }

      if (filters.category !== 'all') {
        params.category_id = filters.category;
        query.category_id = filters.category;
      } else {
        delete query.category_id;
      }

      if (filters.status !== 'all') {
        params.status = filters.status;
        query.status = filters.status;
      } else {
        delete query.status;
      }

      if (this.$route.query && this.$route.query.search) {
        params.search = this.$route.query.search;
      }

      const response = await Products.getProducts({ params });
      this.products = response.data;

      if (Object.keys(query).length != Object.keys({...this.$route.query}).length) {
        this.$router.replace({ name: 'ProductList', query });
      }
    },
    editProduct(product) {
      this.$router.push({
        name: 'SuperProductEdit',
        params: { id: product.id },
      });
    },
    async deleteProduct(id) {
      await Products.deleteProduct(id);
      this.confirmDelete = false;
      this.products = [];
      this.fetchProducts();
      if (this.error) {
        this.innerError = this.error;
      } else {
        this.successDelete = true;
      }
    },
    async toggleProductActive(id, is_active = false) {
      const response = await Products.editProduct(id, {
        is_active: is_active ? 1 : 0,
        activate_deactivate: 1,
      });
      if (response.status === 200) {
        this.fetchProducts();
      }
    },
    vendorName(item) {
      return item?.user?.company?.name;
    },
    getProductStocks(item) {
      if (item.variants && item.variants.length > 0) {
        return item.variants.reduce((sum, variant) => sum + variant.stocks, 0);
      }

      return parseInt(item.stocks);
    },
    hasStock(item) {
      const stock = this.getProductStocks(item);

      return stock > 0;
    },
  },

  async mounted() {
    await this.fetchCategories();
    await this.fetchCompanies();
    await this.fetchProducts();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
@import '@/scss/theme/_border';

.add-product-item {
  img {
    width: 100px;
    height: 100px;
  }
  @extend .bg-gray-light-4;
  @extend .border-gray-light-4;
  @extend .border-1;
  @extend .border-solid;
  &:hover,
  &.active {
    @extend .bg-primary-light-5;
    @extend .border-primary;
  }
}
</style>
